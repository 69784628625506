
















































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { INCEPTION_STATS } from "@/store/modules/recruiter/constants";
import { GET_COMPANY_DETAILS } from "@/store/modules/auth/constants";
import {
  AppAssessmentNames,
  DashboardStats
} from "@/store/modules/common/interfaces";
import { DASHBOARD_COUNTS } from "@/store/modules/recruiter/constants";
import VueApexCharts from "vue-apexcharts";
import * as XLSX from "xlsx";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
export default Vue.extend({
  name: "InceptionDashboardNumbers",
  data() {
    return {
      screenCount: 0,
      graph: 2,
      options: {},
      series: [0, 0],
      barchartOptions: {
        chart: {
          stacked: true,
          id: "vuechart-example",
          type: "bar", // Ensure it's a bar chart
          toolbar: {
            show: false // Hide toolbar (optional for cleaner UI)
          }
        },
        xaxis: {
          categories: [
            this.$t(
              "assessments.dashboard.recruiter.registered-candidates"
            ) as string,
            // 1,
            this.$t("assessments.dashboard.recruiter.candidates") as string,

            // 2,
            this.$t(
              "assessments.dashboard.recruiter.auto-approved-candidates"
            ) as string,
            // 3,
            this.$t(
              "assessments.dashboard.recruiter.eng-assessment-candidates"
            ) as string,
            this.$t(
              "assessments.dashboard.recruiter.annotation-assessment-candidates"
            ) as string,
            this.$t(
              "assessments.dashboard.recruiter.cancelled-assessments"
            ) as string,
            // 6,
            this.$t(
              "assessments.dashboard.recruiter.failed-candidates"
            ) as string,
            // 7,
            this.$t(
              "assessments.dashboard.recruiter.passed-candidates"
            ) as string
          ] as string[],

          labels: {
            rotate: -20,
            style: {
              fontSize: "0.9vw",
              fontWeight: "bold",
              colors: ["#4e4b66"]
              // word-wrap: b"reak-word"
            },
            // margin-left: "5vw",
            // maxWidth: 100, // Control max width before wrapping
            // trim: true,
            offsetX: 0, // Add space to prevent overlap
            offsetY: 10
          }
        },
        yaxis: {
          title: {
            text: "No. of Candidates",
            style: {
              fontSize: "14px",
              fontWeight: "bold"
            }
          },
          labels: {
            style: {
              fontSize: "12px",
              colors: ["#4e4b66"]
            }
          }
        },
        grid: {
          show: true, // Adds grid lines
          borderColor: "#e0e0e0", // Light grid color
          strokeDashArray: 4
        },
        colors: ["#004141", "#d8f2f1"], // Bar colors
        legend: {
          show: false // Hide legend for clean UI
        },
        dataLabels: {
          enabled: false // Disable data labels
        },
        tooltip: {
          shared: true, // Show a combined tooltip for both series
          intersect: false, // Ensure tooltips appear for all overlapping points
          y: {
            formatter: function (graph: any) {
              return `${graph} Candidates`; // Customize tooltip text
            }
          }
        },

        plotOptions: {
          bar: {
            horizontal: false,
            distributed: false,
            columnWidth: "50vw",
            borderRadiusApplication: "end",
            borderRadius: 8 // Optional: Add rounded corners
          }
        }
      },
      barseries: [] as any[],

      chartOptions: {
        data: [
          {
            indexLabel: "Yes"
          }
        ],
        chart: {
          type: "donut"
        },
        labels: ["Completed"], // You can rename it
        legend: {
          show: false // Hides the legend
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            donut: {
              size: "73%", // Adjust the inner circle size
              labels: {
                show: true,
                name: {
                  show: false // Hide the default name label
                },
                value: {
                  show: true,
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#004141"
                },
                total: {
                  show: true,
                  formatter: function (val: any) {
                    return `${Number(
                      (val.globals.seriesTotals[1] /
                        val.globals.seriesTotals[0]) *
                        100
                    ).toFixed(0)} %`;
                  }
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: false // Disable hover tooltips
        },
        states: {
          hover: {
            filter: {
              type: "none" // Disable hover effects
            }
          }
        },
        colors: ["#d8f2f1", "#004141"], // Donut color
        stroke: {
          show: false // Smooth edges by removing stroke
        }
      },
      snakeGraph: require("@/assets/icons/snakeGraph.svg"),
      UsersGroupTwoRounded: require("@/assets/icons/bold/UsersGroupTwoRounded.svg"),
      UserCheckRounded: require("@/assets/icons/bold/UserCheckRounded.svg"),
      UsersGroupRounded: require("@/assets/icons/bold/UsersGroupRounded.svg"),
      UsersGroupRounded1: require("@/assets/icons/bold/UsersGroupRounded1.svg"),
      UsersGroupRounded2: require("@/assets/icons/bold/UsersGroupRounded2.svg"),
      auto_approve_candidate: require("@/assets/icons/bold/auto_approve_candidate.svg"),
      cancelled: require("@/assets/icons/bold/cancelled.svg"),
      clock: require("@/assets/icons/bold/clock.svg"),
      retaken: require("@/assets/icons/bold/retaken.svg"),
      failed_candidate: require("@/assets/icons/bold/failed_candidate.svg"),
      history: require("@/assets/icons/bold/history.svg"),
      unique_jobs_icon: require("@/assets/icons/bold/document-text.svg"),
      graph_up: require("@/assets/icons/bold/graph-up.svg"),
      graph_bar: require("@/assets/icons/bold/bar-2.svg"),
      community_members_icon: require("@/assets/icons/bold/brifecase-tick.svg"),
      registered_employees_icon: require("@/assets/icons/bold/people.svg"),
      eye: require("@/assets/icons/eye.svg"),
      members_percentage: "0",
      assessment: require("@/assets/icons/bold/retaken.svg"),
      twice: require("@/assets/icons/bold/UsersGroupRounded1.svg"),
      signup: require("@/assets/icons/bold/clock.svg"),
      screenswitch: require("@/assets/icons/bold/failed_candidate.svg")
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      inception_stats: INCEPTION_STATS,
      dashboard_counts: DASHBOARD_COUNTS
    }),
    ...mapGetters("auth", {
      get_company: GET_COMPANY_DETAILS
    }),
    eng_assessment_version(): string {
      const version =
        this.get_company?.s3_path?.assessment_setting?.candidates_english;
      if (version === AppAssessmentNames.english_v1) return "v1";
      else if (version === AppAssessmentNames.english_v2) return "v2";
      else return "v1";
    },
    annotation_assessment_version(): string {
      const version =
        this.get_company?.s3_path?.assessment_setting?.candidates_annotation;
      if (version === AppAssessmentNames.annotation_v1) return "v1";
      else if (version === AppAssessmentNames.annotation_v2) return "v2";
      else return "v1";
    }
  },
  async mounted() {
    // document.addEventListener("visibilitychange", this.handleVisibilityChange);
    await this.inception_stats_fetch();
    await this.get_dashboard_counts();
    this.updateBarChartData();

    console.log(
      this.dashboard_counts.anno_part1_avg_time,
      "===========================avgtyime"
    );
    if (
      this.inception_stats.users > 0 &&
      this.inception_stats.last_week_members > 0
    ) {
      if (
        this.inception_stats.users - this.inception_stats.last_week_members >
        0
      )
        this.members_percentage = (
          (this.inception_stats.last_week_members /
            (this.inception_stats.users -
              this.inception_stats.last_week_members)) *
          100
        ).toFixed(0);
      else
        this.members_percentage = (
          this.inception_stats.last_week_members * 100
        ).toFixed(0);
    }
  },
  methods: {
    ...mapActions("recruiter", {
      inception_stats_fetch: INCEPTION_STATS,
      get_dashboard_counts: DASHBOARD_COUNTS
    }),
    handleVisibilityChange() {
      if (document.hidden) {
        this.screenCount++;
        console.log("User switched to another tab or window.");
      } else {
        this.screenCount++;
        console.log("User returned to the app.");
      }
    },
    updateBarChartData() {
      // Dynamically update the bar chart series data when inception_stats changes
      this.barseries = [
        {
          name: "  ",
          data: [
            this.dashboard_counts.registered_users,
            this.dashboard_counts.admin_approved_candidates,
            this.dashboard_counts.auto_approved_candidates,
            this.dashboard_counts.eng_assessment_candidates,
            this.dashboard_counts.anno_assessment_candidates,
            this.dashboard_counts.cancelled_assessments,
            this.dashboard_counts.failed_assessments,
            this.dashboard_counts.passed_assessments
          ]
        }
        // {
        //   name: " ",
        //   data: [
        //     100 - this.dashboard_counts.admin_approved_candidates,
        //     100 - this.dashboard_counts.admin_approved_candidates,
        //     100 - this.dashboard_counts.auto_approved_candidates,
        //     100 - this.dashboard_counts.eng_assessment_candidates,
        //     100 - this.dashboard_counts.anno_assessment_candidates,
        //     100 - this.dashboard_counts.cancelled_assessments,
        //     100 - this.dashboard_counts.failed_assessments,
        //     100 - this.dashboard_counts.passed_assessments
        //   ]
        // }
        // Add more series as needed
      ];

      this.series = [
        this.dashboard_counts.registered_users,
        this.dashboard_counts.new_candidate_count
      ];

      // this.chartOptions.plotOptions.pie.donut.labels.total.formatter = () => {
      // return "20%";
      // return "%";
      // };
      console.log("this.barseries: ", this.barseries[0].data[0]);
    },
    transform_unique_jobs_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#FF8473");
      return icon;
    },
    transform_purple_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#5D37FF");
      return icon;
    },
    transform_pink_dark_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#FF3766");
      return icon;
    },
    transform_community_members_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#EE49FD");
      return icon;
    },
    transform_registered_employees_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#ADE3E3");
      return icon;
    },
    transform_dark_primary_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#59C09C");
      return icon;
    },
    transform_total_candidates(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#004141");
      return icon;
    },
    get_assessments_percentage(value: string) {
      return Math.round((parseInt(value) / this.inception_stats.users) * 100);
    },
    transform_light_blue(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#33BFFF");
      return icon;
    },
    transform_purple_blue(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#5480FF");
      return icon;
    },
    transform_dark_purple(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#8628ff");
      return icon;
    },
    transform_light_green(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#379282");
      return icon;
    },
    transform_yellow(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#ffb83d");
      return icon;
    },
    transform_dark_blue(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#2C32A9");
      return icon;
    },
    downloadExcel() {
      // Convert the object into an array of key-value pairs
      let stats = this.dashboard_counts;

      const formattedData = Object.entries(stats)
        .filter(
          ([key, value]) =>
            key !== "id" && key !== "company_id" && value !== null
        ) // Exclude keys 'id' and 'company_id'
        .map(([key, value]) => ({
          Key: DashboardStats[key as keyof typeof DashboardStats],
          Value: value !== null ? value : "N/A" // Replace null values with "N/A"
        }));

      // Create a worksheet from the formatted data
      const worksheet = XLSX.utils.json_to_sheet(formattedData);

      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Stats");

      // Write the file and download it
      XLSX.writeFile(workbook, "Dashboard_Stats.xlsx");
    }
  }

  // beforeUnmount() {
  //   document.removeEventListener(
  //     "visibilitychange",
  //     this.handleVisibilityChange
  //   );
  // }
});
